@footer-height: 44px;

.footer-fixed {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  .padding-large-lr;
  min-height: @footer-height;
  border-top: 1px solid @gray-lighter;
  background-color: @slate-lightest;
  z-index: 60;
}
.footer-fixed-spacer {
  height: @footer-height;
}
