@spacing-small: 4px;
@spacing-normal: 8px;
@spacing-normal-large: 12px;
@spacing-large: 16px;
@spacing-xlarge: 24px;

.padding-tb {
  padding-top: @spacing-normal;
  padding-bottom: @spacing-normal;
}
.padding-nl-tb {
  padding-top: @spacing-normal-large;
  padding-bottom: @spacing-normal-large;
}
.padding-xlarge-tb {
  padding-top: @spacing-xlarge;
  padding-bottom: @spacing-xlarge;
}
.padding-large-tb {
  padding-top: @spacing-large;
  padding-bottom: @spacing-large;
}
.padding-small-tb {
  padding-top: @spacing-small;
  padding-bottom: @spacing-small;
}

.padding {
  padding: @spacing-normal;
}
.padding-nl {
  padding: @spacing-normal-large;
}
.padding-xlarge {
  padding: @spacing-xlarge;
}
.padding-large {
  padding: @spacing-large;
}
.padding-small {
  padding: @spacing-small;
}

.padding-t {
  padding-top: @spacing-normal;
}
.padding-b {
  padding-bottom: @spacing-normal;
}
.padding-nl-t {
  padding-top: @spacing-normal-large;
}
.padding-nl-b {
  padding-bottom: @spacing-normal-large;
}
.padding-xlarge-t {
  padding-top: @spacing-xlarge;
}
.padding-xlarge-b {
  padding-bottom: @spacing-xlarge;
}
.padding-large-t {
  padding-top: @spacing-large;
}
.padding-large-b {
  padding-bottom: @spacing-large;
}
.padding-small-t {
  padding-top: @spacing-small;
}
.padding-small-b {
  padding-bottom: @spacing-small;
}

.padding-lr {
  padding-left: @spacing-normal;
  padding-right: @spacing-normal;
}
.padding-nl-lr {
  padding-left: @spacing-normal-large;
  padding-right: @spacing-normal-large;
}
.padding-xlarge-lr {
  padding-left: @spacing-xlarge;
  padding-right: @spacing-xlarge;
}
.padding-large-lr {
  padding-left: @spacing-large;
  padding-right: @spacing-large;
}
.padding-small-lr {
  padding-left: @spacing-small;
  padding-right: @spacing-small;
}
.padding-l {
  padding-left: @spacing-normal;
}
.padding-r {
  padding-right: @spacing-normal;
}
.padding-nl-l {
  padding-left: @spacing-normal-large;
}
.padding-nl-r {
  padding-right: @spacing-normal-large;
}
.padding-xlarge-l {
  padding-left: @spacing-xlarge;
}
.padding-xlarge-r {
  padding-right: @spacing-xlarge;
}
.padding-large-l {
  padding-left: @spacing-large;
}
.padding-large-r {
  padding-right: @spacing-large;
}
.padding-small-l {
  padding-left: @spacing-small;
}
.padding-small-r {
  padding-right: @spacing-small;
}

.margin {
  margin: @spacing-normal;
}
.margin-nl {
  margin: @spacing-normal-large;
}
.margin-xlarge {
  margin: @spacing-xlarge;
}
.margin-large {
  margin: @spacing-large;
}
.margin-small {
  margin: @spacing-small;
}

.margin-tb {
  margin-top: @spacing-normal;
  margin-bottom: @spacing-normal;
}
.margin-nl-tb {
  margin-top: @spacing-normal-large;
  margin-bottom: @spacing-normal-large;
}
.margin-xlarge-tb {
  margin-top: @spacing-xlarge;
  margin-bottom: @spacing-xlarge;
}
.margin-large-tb {
  margin-top: @spacing-large;
  margin-bottom: @spacing-large;
}
.margin-small-tb {
  margin-top: @spacing-small;
  margin-bottom: @spacing-small;
}
.margin-t {
  margin-top: @spacing-normal;
}
.margin-b {
  margin-bottom: @spacing-normal;
}
.margin-nl-t {
  margin-top: @spacing-normal-large;
}
.margin-nl-b {
  margin-bottom: @spacing-normal-large;
}
.margin-xlarge-t {
  margin-top: @spacing-xlarge;
}
.margin-xlarge-b {
  margin-bottom: @spacing-xlarge;
}
.margin-large-t {
  margin-top: @spacing-large;
}
.margin-large-b {
  margin-bottom: @spacing-large;
}
.margin-small-t {
  margin-top: @spacing-small;
}
.margin-small-b {
  margin-bottom: @spacing-small;
}

.margin-lr {
  margin-left: @spacing-normal;
  margin-right: @spacing-normal;
}
.margin-nl-lr {
  margin-left: @spacing-normal-large;
  margin-right: @spacing-normal-large;
}
.margin-xlarge-lr {
  margin-left: @spacing-xlarge;
  margin-right: @spacing-xlarge;
}
.margin-large-lr {
  margin-left: @spacing-large;
  margin-right: @spacing-large;
}
.margin-small-lr {
  margin-left: @spacing-small;
  margin-right: @spacing-small;
}
.margin-l {
  margin-left: @spacing-normal;
}
.margin-r {
  margin-right: @spacing-normal;
}
.margin-nl-l {
  margin-left: @spacing-normal-large;
}
.margin-nl-r {
  margin-right: @spacing-normal-large;
}
.margin-xlarge-l {
  margin-left: @spacing-xlarge;
}
.margin-xlarge-r {
  margin-right: @spacing-xlarge;
}
.margin-large-l {
  margin-left: @spacing-large;
}
.margin-large-r {
  margin-right: @spacing-large;
}
.margin-small-l {
  margin-left: @spacing-small;
}
.margin-small-r {
  margin-right: @spacing-small;
}
