// @import (css) url("https://fonts.googleapis.com/css?family=Roboto:300,400,700|Open+Sans:400,700");
//@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,700|Source+Sans+Pro:400,700");
@import '../core/colors.less';

@font-face {
  font-family: 'Avenir';
  src: url('/c/assets/fonts/avenir-roman-400-webfont.woff2') format('woff2'),
    url('/c/assets/fonts/avenir-roman-400-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('/c/assets/fonts/avenir-medium-500-webfont.woff2') format('woff2'),
    url('/c/assets/fonts/avenir-medium-500-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  src: url('/c/assets/fonts/avenir-heavy-900-webfont.woff2') format('woff2'),
    url('/c/assets/fonts/avenir-heavy-900-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@roboto: 'Roboto';

// font faces / sizes
//@text-color: @gray-darker;
//@headings-color: @gray-darker;
@text-color: @gray-darkest;
@headings-color: @gray-darkest;
@font-size-base: 14px;
@line-height-base: 1.4;
@link-color: @blue;

// Roboto / Helvetica Neue
@font-family-sans-serif: 'Roboto', sans-serif;
//@font-family-sans-serif:  "Source Sans Pro", sans-serif;
@source-sans: @font-family-sans-serif;
@headings-font-family: 'Roboto', sans-serif;
@roboto: @headings-font-family;
@opensans: 'Open Sans', sans-serif;
//@headings-font-weight:    300;
@headings-font-weight: bold;
@headings-light-font-weight: 300;
@font-size-h1: 24px;
//@font-size-h1:            36px;
@font-size-h2: 20px;
//@font-size-h2:            28px;
@font-size-h3: 18px;
//@font-size-h3:            22px;
@font-size-h4: 16px;
@font-size-h5: 14px;
@font-size-h6: 12px;

//h1, h2, h3, h4, h5, h6 {
//    letter-spacing: -0.18px;
//}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: -0.12px;
  margin: 0;
  padding: 0;
  //strong {
  font-weight: 400;
  //}
  b {
    font-weight: 700;
  }

  &.header {
    background-color: @slate-light;
    padding: 14px;
  }
  &.inline {
    display: inline-block;
  }
}
.font-h1 {
  font-size: @font-size-h1;
}
.font-h2 {
  font-size: @font-size-h2;
}
.font-h3 {
  font-size: @font-size-h3;
}
.font-h4 {
  font-size: @font-size-h4;
}
.font-h5 {
  font-size: @font-size-h5;
}
.font-h6 {
  font-size: @font-size-h6;
}
.bold,
b {
  font-weight: bold;
}

p {
  font-family: @opensans;
  line-height: 16px;
  &.small {
    font-size: 12px;
  }
}
.open-sans {
  font-family: @opensans;
}

/*
a {
    color: @blue;
    text-decoration: none !important;
}
*/

.italicized {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-style: italic;
}

html {
  font-family: @font-family-sans-serif;
  font-size: 14px;
  font-weight: 200;
}
