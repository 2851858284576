@import '../core/flexbox.less';
@import '../core/spacing.less';

.inline-block {
  display: inline-block;
  vertical-align: top;
}
.nowrap {
  white-space: nowrap;
}
.relative {
  position: relative;
}
.form-input {
  display: inline-block;
  width: 100%;
  .margin-b;
}
.center {
  text-align: center;
}
.margin-center {
  margin-left: auto;
  margin-right: auto;
}

.columns {
  .flexbox;

  .col-2 {
    .flex1;
  }
}
