@use '@angular/material' as mat;

$orange: #ed8000;
$orange-light: #ffeed9;
$orange-dark: #f26724;

$blue: #4d8bbe;
$blue-medium: #46b1e1;
$blue-light: #85d4f8;
$blue-lighter: #b6e6fb;
$blue-lightest: #e5f6fe;
$blue-3: #0583ff;

$gray: #b6b8ba;
$gray-darkest: #4c4f52;
$gray-darker: #747678;
$gray-dark: #8f9194;
$gray-light: #d2d3d5;
$gray-lighter: #e6e6e6;
$gray-lightest: #edeeef;
$gray-really-light: #fbfbfb;

$slate: #647f94;
$slate-darkest: #2d3a45;
$slate-darker: #465460;
$slate-dark: #556879;
$slate-medium: #95aabb;
$slate-light: #d7e2ea;
$slate-lighter: #e8eef2;
$slate-lightest: #f8f9fa;

$green: #78a240;
$green-medium: #9ac61e;
$green-light: #ebf6cc;

$red: #d0021b;
$red-light: #fee2e2;
$pl-red: #ff5a60;

$yellow: #f9b417;
$yellow-light: #fdebc1;
$yellow-dark: #f0b643;

$purple: #b665a6;

$cream: #f8f3e0;

$black: #000000;
$white: #ffffff;

$pl-color-mapping: (
  'blue': $blue,
  'blue-medium': $blue-medium,
  'blue-light': $blue-light,
  'slate': $slate,
  'slate-darkest': $slate-darkest,
  'slate-darker': $slate-darker,
  'slate-dark': $slate-dark,
  'slate-medium': $slate-medium,
  'slate-light': $slate-light,
  'slate-lighter': $slate-lighter,
  'slate-lightest': $slate-lightest,
);

$PL-brand: (
  50: #fdf0e0,
  100: $orange-light,
  200: #f6c080,
  300: #f2a64d,
  400: #f09326,
  500: $orange,
  600: #eb7800,
  700: #e86d00,
  800: $orange-dark,
  900: #e05000,
  A100: #ffffff,
  A200: #ffe1d4,
  A400: #ffbda1,
  A700: #ffab88,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: $white,
    600: $white,
    700: $white,
    800: $white,
    900: $white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: $white,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$pl-primary-palette: mat.define-palette(mat.$blue-gray-palette);
$pl-secondary-palette: mat.define-palette(mat.$indigo-palette);
$pl-accent-palette: mat.define-palette($PL-brand, 500);
$pl-warn-palette: mat.define-palette(mat.$amber-palette);

// Create the theme object (a Sass map containing all of the palettes).
$pl-light-theme: mat.define-light-theme(
  (
    color: (
      primary: $pl-primary-palette,
      secondary: $pl-secondary-palette,
      accent: $pl-accent-palette,
    ),
  )
);
// $pl-light-theme: mat-light-theme($pl-primary-palette, $pl-accent-palette, $pl-warn-palette);
$pl-dark-theme: mat.define-dark-theme(
  $pl-primary-palette,
  $pl-accent-palette,
  $pl-warn-palette
);
