.flexbox {
  display: flex;
}
.flex1 {
  flex: 1;
}
.flex2 {
  flex: 2;
}
.flex3 {
  flex: 3;
}
.flex-start {
  justify-items: flex-start;
}
.items-center {
  align-items: center;
}
.gap-8 {
  gap: 8px;
}
.grow-2 {
  flex-grow: 2 !important;
}
.grow-4 {
  flex-grow: 4 !important;
}
