@import '../core/colors.less';
@import '../core/typography.less';

a,
.a-link {
  color: @blue-medium;
  text-decoration: none;
  cursor: pointer;

  &:focus {
    box-shadow: inset 0px 0px 0px 1px @blue-medium;
  }

  &:hover {
    text-decoration: underline;
  }

  &.link-no-color {
    color: inherit;
  }

  &.link-underline {
    text-decoration: underline;
  }

  &.link-unstyled {
    &:hover {
      text-decoration: none;
    }
  }

  &.link-none {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}

.pointer {
  cursor: pointer;
}
