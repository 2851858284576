/* Copied from bootstrap (types.less) */

// variables.less

@body-bg: #fff;
@text-color: @gray-darker;

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

@font-family-sans-serif: 'Helvetica Neue', Helvetica, Arial, sans-serif;
@font-family-serif: Georgia, 'Times New Roman', Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
@font-family-monospace: Menlo, Monaco, Consolas, 'Courier New', monospace;
@font-family-base: @font-family-sans-serif;

@font-size-base: 14px;
@font-size-large: ceil((@font-size-base * 1.25)); // ~18px
@font-size-small: ceil((@font-size-base * 0.85)); // ~12px

@font-size-h1: floor((@font-size-base * 2.6)); // ~36px
@font-size-h2: floor((@font-size-base * 2.15)); // ~30px
@font-size-h3: ceil((@font-size-base * 1.7)); // ~24px
@font-size-h4: ceil((@font-size-base * 1.25)); // ~18px
@font-size-h5: @font-size-base;
@font-size-h6: ceil((@font-size-base * 0.85)); // ~12px

//** Unit-less `line-height` for use in components like buttons.
@line-height-base: 1.428571429; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
@line-height-computed: floor((@font-size-base * @line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
@headings-font-family: inherit;
@headings-font-weight: 500;
@headings-line-height: 1.1;
@headings-color: inherit;

@headings-small-color: @gray-light;

// scaffolding.less
// Body reset

html {
  font-size: 14px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: @font-family-base;
  font-size: 1rem;
  line-height: @line-height-base;
  color: @text-color;
  background-color: @body-bg;
}

// Reset fonts for relevant elements
input,
//button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

// types.less

// Headings
// -------------------------

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: @headings-font-family;
  font-weight: @headings-font-weight;
  line-height: @headings-line-height;
  //color: @headings-color;

  small,
  .small {
    font-weight: normal;
    line-height: 1;
    color: @headings-small-color;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  margin-top: @line-height-computed;
  margin-bottom: (@line-height-computed / 2);

  small,
  .small {
    font-size: 65%;
  }
}
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: (@line-height-computed / 2);
  margin-bottom: (@line-height-computed / 2);

  small,
  .small {
    font-size: 75%;
  }
}

h1,
.h1 {
  font-size: @font-size-h1;
}
h2,
.h2 {
  font-size: @font-size-h2;
}
h3,
.h3 {
  font-size: @font-size-h3;
}
h4,
.h4 {
  font-size: @font-size-h4;
}
h5,
.h5 {
  font-size: @font-size-h5;
}
h6,
.h6 {
  font-size: @font-size-h6;
}

/* end: copied from bootstrap */
