.dot(@color) {
  background-color: @color;
  display: inline-block;
  width: 11px;
  height: 11px;
  border-radius: 11px;
}

.orange-dot {
  .dot(@orange);
}

.yellow-dot {
  .dot(@yellow);
}

.yellow-dark-dot {
  .dot(@yellow-dark);
}

.green-dot {
  .dot(@green);
}

.blue-dot {
  .dot(@blue);
}

.purple-dot {
  .dot(@purple-2);
}

.gray-dot {
  .dot(@gray);
}

.light-gray-dot {
  .dot(@gray-6);
}

.dark-gray-dot {
  .dot(@gray-5);
}

.green-medium-dot {
  .dot(@green-medium);
}
