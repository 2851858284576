@import './pl-colors.scss';

.mat-button-base {
  font-family: Avenir;
  //The .cdk-focused class is typically added by Angular Material to elements that have received focus, either through keyboard navigation or programmatic focus.
  &.cdk-focused {
    box-shadow: $gray 0px 0px 0px 3px;
  }
}

// this set of buttons are filled in
.mat-flat-button,
.mat-raised-button,
.mat-fab,
.mat-mini-fab {
  &.mat-primary {
    background-color: $pl-red;
  }
  &.mat-accent {
    &:hover {
      background-color: $orange-dark;
    }
  }
}

// this set of buttons have a text color, but are not filled in
.mat-button,
.mat-stroked-button,
.mat-icon-button {
  &.mat-primary {
    color: $pl-red;
  }
}

// this singular button has a border
.mat-stroked-button {
  &.mat-primary:not([disabled]) {
    border: 1px solid $pl-red;
  }
}
