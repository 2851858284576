.section-header {
  background-color: @slate-lighter;
  .padding-large;
}

.section-header-with-help {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  .learn-more {
    margin: 0px;
    font-size: 11px;
  }
}

.section-body {
  background-color: @slate-lightest;
  .padding-large;
}

.header-blue {
  color: @white;
  background-color: @slate-darkest;
  //line-height: 25px;

  &.fixed {
    position: fixed;
    left: 0;
    z-index: 9999;
    width: 100%;
    box-sizing: border-box;
  }
}
.header-blue-fixed-spacer {
  height: 45px;
}
